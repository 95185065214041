<!--
 * @Author: lbh
 * @Date: 2021-04-29 15:58:23
 * @LastEditors: lbh
 * @LastEditTime: 2021-04-30 09:47:00
 * @Description: 輸入框
-->
<template>
  <div class="input-lable">
    <div
      class="input-box"
      :class="oClass"
    >
      <input
        ref="input"
        :value="value"
        :type="type"
        @focus="onFocus"
        @blur="onBlur"
        @input="$emit('content',$event.target.value)"
      />
    </div>
    <div
      class="input-error"
      v-if="errorText"
      v-text="errorText"
    ></div>
  </div>
</template>
<script>
export default {
  name: "self-input",
  props: {
    // 值
    value: {
      default: () => {
        return '';
      }
    },
    // 輸入框類型
    type: {
      default: () => {
        return 'text'
      }
    },
    // 是否需要校驗
    must: {
      default: () => {
        return false;
      }
    },
    rules: {
      default: () => {
        return ''
      }
    }
  },
  model: {
    prop: 'value',
    event: "content"
  },
  watch:{
    rules(){
      this.onError();
    }
  },
  data () {
    return {
      oClass: 'input-un',
      errorText: ''
    }
  },
  methods: {
    // 失去焦點
    onBlur (e) {
      this.oClass = 'input-un';
      // 校驗
      this.onError();
    },
    // 獲得焦點
    onFocus (e) {
      this.oClass = 'input-se'
    },
    onError () {
      if (this.must) {
        this.errorText = '';
        if (!this.value) {
          this.errorText = '必填選項!';
        } else {
          if (this.type == 'email') {
            let pattern = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
            if (!pattern.test(this.value)) {
              this.errorText = '電郵格式不正確!';
            }
          } else if (this.type == 'tel') {
            if (this.value.length != 8 && this.value.length != 11) {
              this.errorText = '手機格式不正確!';
            }
          }
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.input-box {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  &::after {
    position: absolute;
    content: "";
    height: 1px;
    left: 0;
    right: 0;
    background-color: #ddd;
    bottom: 0;
  }
  &::before {
    position: absolute;
    content: "";
    height: 3px;
    left: 0;
    right: 0;
    background-color: #900000;
    bottom: 0;
  }
  input {
    width: 100%;
    border: none;
    padding: 10px 0;
    line-height: 22px;
    color: #555;
    height: 22px;
    font-size: 16px !important;
    background: transparent;
    &:focus {
      outline: none;
    }
  }
}

.input-un {
  &::before {
    -webkit-transform: translate3d(-101%, 0, 0);
    transform: translate3d(-101%, 0, 0);
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.24, 1, 0.3, 1),
      border-color 0.2s cubic-bezier(0.39, 0.71, 0.56, 0.98);
    transition: transform 0.4s cubic-bezier(0.24, 1, 0.3, 1),
      border-color 0.2s cubic-bezier(0.39, 0.71, 0.56, 0.98);
  }
}

.input-se {
  &::before {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.24, 1, 0.3, 1),
      border-color 0.2s cubic-bezier(0.39, 0.71, 0.56, 0.98);
    transition: transform 0.4s cubic-bezier(0.24, 1, 0.3, 1),
      border-color 0.2s cubic-bezier(0.39, 0.71, 0.56, 0.98);
  }
}

.input-error {
  padding: 5px 6px;
  background-color: #fff;
  box-shadow: 0 1px 2px #ccc;
  color: #980000;
}
</style>
